import 'react-native-gesture-handler';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import React, { useState, useEffect } from 'react';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { ApiGet } from './src/services/ApiServices';

import { Loading } from './src/components/Elements';

//--AuthFlow
import Login from './src/screens/Login';
import Cadastro from './src/screens/Cadastro';
import Termos from './src/screens/Termos';

//--AppFlow
import { HeaderTitle, BookmarkNotificationConfigButtons } from './src/components/Header';
import AceiteUso from './src/screens/AceiteUso';
import Home from './src/screens/Home';
import TVOnline from './src/screens/TVOnline';
import Categoria from './src/screens/Categoria';
import Conteudo from './src/screens/Conteudo';
import Favoritos from './src/screens/Favoritos';
import Busca from './src/screens/Busca';
import Config from './src/screens/Config';
import MeuCadastro from './src/screens/MeuCadastro';
import MinhaSenha from './src/screens/MinhaSenha';
import FaleConosco from './src/screens/FaleConosco';
import Notificacoes from './src/screens/Notificacoes';

const Stack = createStackNavigator();

const headerOptions = {
    title: 'FarolApp' ,
    headerShown: true, 
    headerStyle: { backgroundColor: '#f8f8f8' }, 
    headerTintColor: '#CCC', 
    cardStyle: { backgroundColor: '#FFFFFF' },
    headerTitle: () => null,
    //HeaderTitleStyle: { fontWeight: 'normal', textAlign: 'left' }
}

const appLinking = {
    //prefixes: [],
    config: {
        screens: {
            // Loader: {
            //     screens: {
            //         Loader: 'loader',
            //     },
            // },
            AuthFlow: {
                screens: {
                    Login: 'app/login',
                    Cadastro: 'app/cadastro',
                    Termos: 'app/termos',
                },
            },
            AppFlow: {
                screens: {
                    Home: 'app/home',
                    AceiteUso: 'app/aceiteuso',
                    TVOnline: 'app/tvonline',
                    Categoria: 'app/categoria',
                    Conteudo: 'app/conteudo',
                    Favoritos: 'app/favoritos',
                    Busca: 'app/busca',
                    Config: 'app/config',
                    MeuCadastro: 'app/meucadastro',
                    MinhaSenha: 'app/minhasenha',
                    FaleConosco: 'app/faleconosco',
                    Notificacoes: 'app/notificacoes',
                },
            },
            Loader: '*'
        },
    },
};

export default function App() {
    return (
        <NavigationContainer linking={appLinking}>
            <Stack.Navigator headerMode="none">
                {/* <Stack.Screen name="Loader" component={Loader} /> */}
                <Stack.Screen name="AuthFlow" component={AuthFlow} />
                <Stack.Screen name="AppFlow" component={AppFlow} />
                <Stack.Screen name="Loader" component={Loader} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}

const Loader = () => {

    const navigation = useNavigation();
    
    useEffect(() => {

        async function checaLogado() {
            const usuario = await AsyncStorage.getItem('usuario');
            setTimeout(() => {
                if (usuario && JSON.parse(usuario).token) {
                    navigation.replace('AppFlow', { screen: 'Home'});
                } else {
                    navigation.replace('AuthFlow', { screen: 'Login'});
                }
            }, 500);
        }
        
        //-- Checa cliente (subdominio)
        var subdominio =  window.location.host.split('.')[0].split(':')[0];
        if (subdominio == "localhost") subdominio = "teste";
        if (subdominio && subdominio != "farolapp") {
            ApiGet('cliente/' + subdominio).then((res) => {
                if (res) {
                    localStorage.setItem('idioma', res.idioma || 'pt');
                    AsyncStorage.setItem('cliente', JSON.stringify(res)).then(() => {
                        checaLogado();
                    });
                }
            });
        }       
        
    }, []);
    
    return (<Loading show={true}></Loading>)
}

const AuthFlow = () => {
    
    return (
        <Stack.Navigator name="AuthFlow" headerMode="screen">
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
            <Stack.Screen name="Cadastro" component={Cadastro} options={{ headerShown: false }} />
            <Stack.Screen name="Termos" component={Termos} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}

const AppFlow = () => {
    
    const [ cliente, setCliente ] = useState({});
    useEffect(() => {
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));
    }, []);
    
    return (
        <Stack.Navigator name="AppFlow" headerMode="screen">
            <Stack.Screen name="Home" component={Home} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="TVOnline" component={TVOnline} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Categoria" component={Categoria} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Conteudo" component={Conteudo} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <></> }} />
            <Stack.Screen name="Favoritos" component={Favoritos} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Busca" component={Busca} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Config" component={Config} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="MeuCadastro" component={MeuCadastro} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="MinhaSenha" component={MinhaSenha} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="FaleConosco" component={FaleConosco} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Notificacoes" component={Notificacoes} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} backButton />, headerRight: () => <BookmarkNotificationConfigButtons cliente={cliente} /> }} />
            <Stack.Screen name="Termos" component={Termos} options={{ headerShown: false }} />
            <Stack.Screen name="AceiteUso" component={AceiteUso} options={{ ...headerOptions, title: cliente.nome, headerLeft: () => <HeaderTitle cliente={cliente} />, headerRight: () => <></> }} />
        </Stack.Navigator>
    )
}

serviceWorkerRegistration.register();