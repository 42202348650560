import React, { useState, useEffect } from 'react';
//import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Iframe from 'react-iframe';

import { Loading } from '../components/Elements';

export default function TVOnline() {

    //const [ t, i18n ] = useTranslation();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState(null);
    const [ usuario, setusuario ] = useState(null);

    useEffect(() => {

        //i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));
        AsyncStorage.getItem('usuario').then((usu) => setusuario(JSON.parse(usu)));

        setLoading(false);

    }, []);

    return(
    <>
        <Loading show={loading} />
        { cliente!=null && usuario!=null && !loading &&
        <View style={{ flex: 1 }}>
            <Iframe 
                url={cliente.url_tvonline + ((cliente.url_tvonline.includes('?') ? '&' :'?') + 'id_usuario=' + usuario.id)} 
                style={{ flex: 1 }}
                frameBorder="0" width="100%" height="100%" //allow="autoplay;encrypted-media"
                allowFullScreen={true} 
                //onMessage={(event) => console.log(event.nativeEvent.data)}
            />
        </View>
        }
    </>
    );
}