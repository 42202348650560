import Alert from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';

import pt from '../../assets/i18n/pt.json';
import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';

const urlBase = 'https://farolapp.com.br/api/';
const apiToken = 'Wm1tZ0ApKTNmM2BAcyo6J0JkVVF5RQ==';

let headersParams = new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'ApiToken': apiToken,
});

let setToken = async () => {

    return AsyncStorage.getItem('usuario').then(res => {
        if (res != null) {
            let usuario = JSON.parse(res);
            if (usuario && (!usuario.token || usuario.token != headersParams.get('UserToken'))) {
                headersParams.set('UserToken', usuario.token || '');
            }
        }
        return;
    });
}

var lang = { pt: pt, en: en, es: es }

export const ApiGet = async (endpoint) => {
    //console.log('Get ' + endpoint);
    return setToken().then(async () => {
        return fetch(urlBase + endpoint.toLowerCase(), {
            method: 'GET',
            headers: headersParams,
        })
        .then(res => res.json())
        .then(res => {
            if (res.ok) {
                return res.obj || res.msg;
            } else {
                var msg = lang[localStorage.getItem('idioma')]["translation"][res.msg];
                if (res.msg) Alert.fire('Ops!', (msg || res.msg), 'error');
                //throw Error(res.msg || res.Message || res);
            }
        }).catch(err => {
            console.log('FAIL (' + endpoint + '):', err);
            //throw Error(err.message || err);
        });
    });
}

export const ApiPost = async (endpoint, payload) => {
    //console.log('Post ' + endpoint);
    return setToken().then(async () => {
        return fetch(urlBase + endpoint.toLowerCase(), {
            method: 'POST',
            headers: headersParams,
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(res => {
            if (res.ok) {
                return res.obj || res.msg;
            } else {
                var msg = lang[localStorage.getItem('idioma')]["translation"][res.msg];
                if (res.msg) Alert.fire('Ops!', (msg || res.msg), 'error');
                //throw Error(res.msg || res.Message || res);
            }
        }).catch(err => {
            console.log('FAIL (' + endpoint + '):', err);
            //throw Error(err.message || err);
        });
    });
}

export const ApiPut = async (endpoint, payload) => {
    //console.log('Put ' + endpoint);
    return setToken().then(async () => {
        return fetch(urlBase + endpoint.toLowerCase(), {
            method: 'PUT',
            headers: headersParams,
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(res => {
            if (res.ok) {
                return res.obj || res.msg;
            } else {
                var msg = lang[localStorage.getItem('idioma')]["translation"][res.msg];
                if (res.msg) Alert.fire('Ops!', (msg || res.msg), 'error');
                //throw Error(res.msg || res.Message || res);
            }
        }).catch(err => {
            console.log('FAIL (' + endpoint + '):', err);
            //throw Error(err.message || err);
        });
    });
}

export const ApiPatch = async (endpoint, payload) => {
    //console.log('Patch ' + endpoint);
    return setToken().then(async () => {
        return fetch(urlBase + endpoint.toLowerCase(), {
            method: 'PATCH',
            headers: headersParams,
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(res => {
            if (res.ok) {
                return res.obj || res.msg;
            } else {
                var msg = lang[localStorage.getItem('idioma')]["translation"][res.msg];
                if (res.msg) Alert.fire('Ops!', (msg || res.msg), 'error');
                //throw Error(res.msg || res.Message || res);
            }
        }).catch(err => {
            console.log('FAIL (' + endpoint + '):', err);
            //throw Error(err.message || err);
        });
    });
}

export const ApiDelete = async (endpoint, payload) => {
    //console.log('Delete ' + endpoint);
    return setToken().then(async () => {
        return fetch(urlBase + endpoint.toLowerCase(), {
            method: 'DELETE',
            headers: headersParams,
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(res => {
            if (res.ok) {
                return res.obj || res.msg;
            } else {
                console.log('ERROR (' + endpoint + '):', res);
                var msg = lang[localStorage.getItem('idioma')]["translation"][res.msg];
                if (res.msg) Alert.fire('Ops!', (msg || res.msg), 'error');
                //throw Error(res.msg || res.Message || res);
            }
        }).catch(err => {
            console.log('FAIL (' + endpoint + '):', err);
            //throw Error(err.message || err);
        });
    });
}