import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text, FlatList } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, SemConteudo, Title, Copyright } from '../components/Elements';

import { ApiGet } from '../services/ApiServices';

export default function Favoritos() {

    const [ t, i18n ] = useTranslation();

    const isFocused = useIsFocused();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ notificacoes, setNotificacoes ] = useState([]);

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {
        if (isFocused) {
            ApiGet('Notificacoes').then((res) => {
                setNotificacoes(res);
                setLoading(false);
            });
        }
    }, [isFocused]);

    return(
    <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Title style={{ color: cliente.cor1, padding: 15, fontSize: 30 }}>{t('NotificacoesRecentes').toUpperCase()}</Title>
            { notificacoes.length>0 &&
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 10 }}
                data={notificacoes}
                renderItem={({ item, index }) => (
                    <View key={index} style={{ margin: 10, marginTop: 0, padding: 10, borderWidth: 1, borderColor: '#f5f5f5', }}>
                        <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                            {item.data}
                        </Text>
                        <Text style={{ color: '#333', fontSize: 12, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 5 }}>
                            {item.mensagem}
                        </Text>
                    </View>
                )}
                ListEmptyComponent={() => 
                    <SemConteudo text={t('MsgSemNotificacao').toUpperCase()} />
                }
            />
            }
            <Copyright />
        </ScrollView>
    </>
    );
}