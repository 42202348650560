import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView, Text, FlatList } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import OneSignal from 'react-onesignal';
import PWAPrompt from 'react-ios-pwa-prompt';

import { Loading, Conteudo, Copyright } from '../components/Elements';
import { Menu } from '../components/Menu';

import { ApiGet, ApiPut } from '../services/ApiServices';

export default function Home() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();
    const isFocused = useIsFocused();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ conteudoDestaque, setConteudoDestaque ] = useState([]);
    const [ conteudoLista, setConteudoLista ] = useState([]);
    
    let onesignalIdCliente = '';

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => {
            var clienteLocal = JSON.parse(cli);
            setCliente(clienteLocal);
            onesignalIdCliente = clienteLocal.onesignal_appid;
            //OneSignal.initialize(onesignalIdCliente);
            OneSignal.init({ appId: onesignalIdCliente });
        });

        var waiting = setInterval(() => {
            if (onesignalIdCliente.length) {
                OneSignal.getUserId().then((onesignalIdUsuario) => {
                    if (onesignalIdUsuario) {
                        ApiPut('OneSignal',onesignalIdUsuario);
                        clearInterval(waiting);
                    }
                });
            }
        }, 5000);

    }, []);

    useEffect(() => {
        if (isFocused) {

            AsyncStorage.getItem('usuario').then((res) => {
                var usuL = JSON.parse(res);
                ApiGet('Usuario').then((usuR) => {
                    var usuLR = {...usuL,...usuR};
                    //setUsuario(usuLR);
                    if (!usuLR.optin) navigation.replace('AceiteUso');
                    AsyncStorage.setItem('usuario',JSON.stringify(usuLR));
                });
                ApiGet('ConteudoCategoria/Feed').then((res) => {
                    setConteudoDestaque(res.filter((cont) => cont.destaque));
                    setConteudoLista(res.filter((cont) => !cont.destaque));
                    setLoading(false);
                });
            });
            
        }
    }, [isFocused]);

    return(
        <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Menu cliente={cliente} categoria={""} />
            {/* Destaques */}
            { conteudoDestaque.length>0 &&
            <FlatList
                horizontal
                style={{ padding: 10 }}
                data={conteudoDestaque}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo destaqueH conteudo={item} cliente={cliente} />
                )}
            />
            }
            {/* Listagem recentes (feed) */}
            <Text style={{ paddingHorizontal: 10, fontSize: 13, fontWeight: 500, color: '#ccc' }}>{t('Feed').toUpperCase()}</Text>
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 10 }}
                data={conteudoLista}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo lista conteudo={item} cliente={cliente} />
                )}
            />
            <Copyright />
        </ScrollView>
        <PWAPrompt copyTitle={t('TermosUso') + " " + cliente.nome} copyBody={t('MsgParaInstalarAppSigaPassos')} copyShareButtonLabel={t('MsgInstalarAppPasso1')} copyAddHomeButtonLabel={t('MsgInstalarAppPasso2')} copyClosePrompt={t('Cancelar')} permanentlyHideOnDismiss={false} />
    </>
    );
}