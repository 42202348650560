import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import {  ScrollView, FlatList } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, Conteudo, SemConteudo, Title, Copyright } from '../components/Elements';

import { ApiGet } from '../services/ApiServices';

export default function Favoritos() {

    const [ t, i18n ] = useTranslation();

    const isFocused = useIsFocused();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ conteudo, setConteudo ] = useState([]);

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {
        if (isFocused) {
            ApiGet('ConteudoCategoria/Favoritos').then((res) => {
                setConteudo(res);
                setLoading(false);
            });
        }
    }, [isFocused]);

    return(
    <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Title style={{ color: cliente.cor1, padding: 15, fontSize: 30 }}>{t('MeusFavoritos').toUpperCase()}</Title>
            { conteudo!=null &&
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 10 }}
                data={conteudo}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo favorito conteudo={item} cliente={cliente} />
                )}
                ListEmptyComponent={() => 
                    <SemConteudo text={t('MsgSemFavoritos')} />
                }
            />
            }
            <Copyright />
        </ScrollView>
    </>
    );
}