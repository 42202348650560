import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, ImageBackground, View, Text, KeyboardAvoidingView, Image } from 'react-native';
import Alert from 'sweetalert2';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PWAPrompt from 'react-ios-pwa-prompt';

import { Loading, Input, Button, Link, InputIcon, Copyright } from '../components/Elements';
import { ApiPut } from '../services/ApiServices';

export default function Intro() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();

    const [ loading, setLoading ] = useState(false);
    const [ cliente, setCliente ] = useState({});
    const [ form, setForm ] = useState({
        cliente: '',
        matricula: '',
        senha: '',
    });
    const [ hideSenha, setHideSenha ] = useState(true);

    let refs = {};

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {

        if (cliente.token) setForm({...form, cliente: cliente.token});

    }, [cliente]);

    let login = () => {
        setLoading(true);
        if (!form.matricula || !form.senha) {
            Alert.fire({
                title: t('Atencao'),
                text: t('MsgPreenchaTodosCampos'),
                icon: 'warning',
                confirmButtonColor: cliente.cor1,
            }).then((result) => {
                if (result.isConfirmed) {
                    
                }
            });
            setLoading(false);
            return;
        }
        ApiPut('Login', form).then((resUsu) => {
            if (resUsu) {
                AsyncStorage.setItem('usuario', JSON.stringify(resUsu)).then(() => {
                    navigation.replace('AppFlow', { screen: resUsu.optin ? 'Home' : 'AceiteUso'});
                });
            }
        }).finally(() => {
            setLoading(false);
            setForm({...form, senha: ''});
        });
    }

    let esqueciSenha = () => {
        setLoading(true);
        setForm({...form, senha: ''});
        if (!form.matricula) {
            Alert.fire({
                //title: 'Atenção',
                text: t('MsgPreenchaLoginEsqueciSenha'),
                icon: 'info',
                confirmButtonColor: cliente.cor1,
            });
            setLoading(false);
            return;
        }
        ApiPut('EsqueciSenha', {
            cliente: cliente.token,
            matricula: form.matricula,
        }).then((res) => {
            Alert.fire({
                //title: 'Atenção',
                text: t(res.msg), //res.msg,
                icon: 'info',
                confirmButtonColor: cliente.cor1,
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    let updateForm = (field,value) => {
        setForm({...form, [field]: value});
    };


    return (
        <ImageBackground style={styles.background} source={{ uri: cliente.background }}>
            <View style={{ flex: 1, justifyContent: 'center' }}>
                { cliente.logo?.length>0 &&
                <Image resizeMode="contain" 
                    source={{ uri: cliente.logo }} 
                    style={{ width:'100%', height: 200, marginTop: 15, marginBottom: 30 }}
                />
                }
            </View>
            <Loading show={loading} />
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
                <Input
                    placeholder={cliente.label_login}
                    value={form.matricula || ''}
                    ref={(ref) => refs.matricula = ref}
                    returnKeyType={'next'} blurOnSubmit={false}
                    onSubmitEditing={() => refs.senha.focus()}
                    onChangeText={(val) => updateForm("matricula",val)}
                />
                <InputIcon
                    autoCompleteType={'off'}
                    placeholder={t('Senha')} 
                    value={form.senha || ''} 
                    secureTextEntry={hideSenha}
                    ref={(ref) => refs.senha = ref}
                    returnKeyType={'go'} blurOnSubmit={true}
                    onSubmitEditing={() => login()}
                    onChangeText={(val) => updateForm("senha",val)}
                    icon={hideSenha ? "eye" : "eye-off"}
                    onPressIcon={()=>setHideSenha(!hideSenha)}
                />
                <Link 
                    onPress={() => esqueciSenha()} 
                    style={{marginBottom: 10}} 
                    styleText={{color: cliente.cor1, textAlign: 'right', fontSize: 10, fontWeight: 'bold'}}
                    text={t('EsqueceuSenha').toUpperCase()} 
                />
                <Button
                    onPress={() => login()}
                    text={<Text>{t('Login').toUpperCase()} <MaterialCommunityIcons name="chevron-right" /></Text>}
                    style={{ backgroundColor: cliente.cor1 }}
                />
                <Link 
                    onPress={() => navigation.push('Cadastro')} 
                    style={{marginTop: 30}} 
                    styleText={{color: cliente.cor2, fontWeight: 'bold'}} 
                    text={t('NaoPossuiCadastro').toUpperCase()} 
                />
                <Link 
                    onPress={() => navigation.push('Termos')} 
                    style={{marginTop: 20}} 
                    styleText={{color: cliente.cor2, fontWeight: 'bold'}} 
                    text={t('TermosUso').toUpperCase()}
                />
            </KeyboardAvoidingView>
            <Copyright />
            <PWAPrompt copyTitle={t('TermosUso') + " " + cliente.nome} copyBody={t('MsgParaInstalarAppSigaPassos')} copyShareButtonLabel={t('MsgInstalarAppPasso1')} copyAddHomeButtonLabel={t('MsgInstalarAppPasso2')} copyClosePrompt={t('Cancelar')} permanentlyHideOnDismiss={false} />
        </ImageBackground>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        resizeMode: "cover",
        padding: 30,
    },
});
