import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView, View, FlatList } from 'react-native';
import { useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, Conteudo, SemConteudo, Title, Copyright, BuscaBar } from '../components/Elements';

import { ApiPost } from '../services/ApiServices';

export default function Busca() {

    const [ t, i18n ] = useTranslation();

    const route = useRoute();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ conteudo, setConteudo ] = useState([]);

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {
        ApiPost('ConteudoBusca', route.params).then((res) => {
            setConteudo(res);
            setLoading(false);
        });
    }, [route.params]);

    return(
    <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Title style={{ color: cliente.cor1, padding: 15, fontSize: 30 }}>Busca de conteúdo</Title>
            <View style={{ padding:10, paddingTop:0 }}>
                <BuscaBar onPress={()=>{}}
                    text={route.params.query}
                    noticias={route.params.noticias}
                    videos={route.params.videos}
                    podcasts={route.params.podcasts}
                    downloads={route.params.downloads}
                />
            </View>
            { conteudo.length>0 &&
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 10 }}
                data={conteudo}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo lista conteudo={item} cliente={cliente} />
                )}
                ListEmptyComponent={() => 
                    <SemConteudo text="Nenhum conteudo encontrado para essa busca..." />
                }
            />
            }
            <Copyright />
        </ScrollView>
    </>
    );
}