import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, ScrollView, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Alert from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons'; 

import { Title, Loading, Copyright, Input, Button } from '../components/Elements';

import { ApiGet, ApiPut, ApiDelete } from '../services/ApiServices';

export default function MeuCadastro() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();
    const [ cliente, setCliente ] = useState({});

    const [ loading, setLoading ] = useState(true);
    const [ form, setForm ] = useState({
        matricula: '',
        nome: '',
        email: '',
        //telefone: '',
    });

    let refs = {};

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

        ApiGet('Usuario').then((res) => {
            setForm({...form, matricula: res.matricula, nome: res.nome, email: res.email});
        }).finally(() => {
            setLoading(false);
        });
        
    }, []);

    let atualizar = () => {
        if (!form.nome || !form.email) {
            Alert.fire(t('Atencao'), t('MsgPrenchaCamposObrigatorios'));
            return;
        }
        setLoading(true);
        ApiPut('Usuario', form).then(res => {
            if (res) {
                AsyncStorage.setItem('usuario', JSON.stringify(res)).then(() => {
                    Alert.fire(t('MsgCadastroAtualizado'));
                    navigation.navigate('Configs');
                });
            };
        }).finally(() => {
            setLoading(false);
        });
    }

    let updateForm = (field,value) => {
        setForm({...form, [field]: value});
    };

    var confirmacao = "";

    let removerConta = () => {

        Alert.fire({
            title: t('Atencao'),
            text: t('MsgConfirmaExclusaoConta'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'orange',
            cancelButtonColor: 'gray',
            confirmButtonText: t('SimEstouCiente'),
            cancelButtonText: t('NaoExcluir'),
        }).then((result) => {
            if (result.isConfirmed) {
                Alert.fire({
                    title: t('MsgConfirmeDigitando'),
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonColor: 'orange',
                    cancelButtonColor: 'gray',
                    confirmButtonText: t('Excluir'),
                    cancelButtonText: t('Cancelar'),
                    //showLoaderOnConfirm: true,
                    preConfirm: (texto) => {
                        confirmacao = texto;
                        console.log(confirmacao);
                    },
                    //allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (confirmacao.toUpperCase() == "CONFIRMO") {
                            ApiDelete('RemoverConta', {}).then((res) => {
                                if (res) {
                                    AsyncStorage.removeItem('usuario').then(() => {
                                        navigation.replace('AuthFlow', { screen: 'Login'});
                                    });
                                }
                            });
                        } else {
                            Alert.fire(t('MsgConfirmeDigitandoErro'));
                        }
                    }
                })
            }
        });

    };

    return(
        <ScrollView style={{ padding: 15 }}>
            <Title style={{ color: cliente.cor1, fontSize: 30, marginBottom: 10 }}>{t('EditarCadastro').toUpperCase()}</Title>

            <Text style={styles.label}>{t('NomeCompleto')}:</Text>
            <Input value={form.nome} autoCompleteType={'name'}
                ref={(ref) => refs.nome = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("nome",val)}
                onSubmitEditing={() => refs.email.focus()}
            />

            <Text style={styles.label}>{t('Email')}:</Text>
            <Input value={form.email} autoCapitalize="none" autoCompleteType={'email'} keyboardType={'email-address'}
                ref={(ref) => refs.email = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("email",val)}
                onSubmitEditing={() => refs.telefone.focus()}
            />

            {/*
            <Text style={styles.label}>{t('Telefone')}:</Text>
            <Input value={form.telefone} autoCompleteType={'tel'} keyboardType={'numeric'} mask={"([00]) [00000]-[0000]"}
                ref={(ref) => refs.telefone = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("telefone",val)}
                onSubmitEditing={() => refs.telefone.focus()}
            />
            */}

            <Button text={t('AtualizarCadastro')} style={{marginTop: 30, backgroundColor: cliente.cor1 }}
                onPress={ () => atualizar() }
            />

            <Pressable style={{ marginVertical: 50 }} onPress={() => removerConta()}>
                <Text style={{ color: 'red' }}>
                    <Ionicons name="trash-outline" style={{ marginRight: 10 }} />
                    {t('DesejoRemoverConta')}
                </Text>
            </Pressable>
            
            <Copyright />
            <Loading show={loading} />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    label: {
        fontSize: 12,
        marginBottom: 3,
    }
});