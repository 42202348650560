import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { Text, ScrollView, FlatList, Dimensions } from 'react-native';
import { useRoute, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, Conteudo, SemConteudo, Copyright } from '../components/Elements';
import { Menu } from '../components/Menu';

import { ApiGet } from '../services/ApiServices';

export default function Categoria() {

    const [ t, i18n ] = useTranslation();

    const route = useRoute();
    const isFocused = useIsFocused();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ conteudoDestaque, setConteudoDestaque ] = useState([]);
    const [ conteudoBlocos, setConteudoBlocos ] = useState([]);

    const windowWidth = Dimensions.get('window').width;
    const imageMaxWidth = Math.min(windowWidth, windowWidth >= 1024 ? 800 : 400);
    const imageMaxHeight = Math.min(Math.round(imageMaxWidth / 4 * 3), windowWidth >= 1024 ? 600 : 300);
    const numColumns = Math.max(2,parseInt(Math.ceil(windowWidth / 360)));
    const imageColumWidth = parseInt(windowWidth / numColumns) - 15;
    const imageColumHeight = parseInt(Math.round(imageColumWidth / 4 * 3));

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {
        if (isFocused) {

            ApiGet('ConteudoCategoria/' + route.params.categoria).then((res) => {
                setConteudoDestaque(res.filter((cont) => cont.destaque));
                setConteudoBlocos(res.filter((cont) => !cont.destaque));
                setLoading(false);
            });

        }
    }, [isFocused]);

    return(
    <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Menu cliente={cliente} categoria={route.params.categoria} />
            {/* <Title style={{ color: cliente.cor1, padding: 10, fontSize: 20 }}>{route.params.categoria.toUpperCase() + (route.params.categoria.toUpperCase() != "UNIDADE" ? "S" : "") + (route.params.categoria.toUpperCase() == "LINK" ? " ÚTEIS" : "")}</Title> */}
            <Text style={{ paddingHorizontal: 10, fontSize: 13, fontWeight: 500, color: '#ccc' }}>{route.params.categoria.toUpperCase() + (route.params.categoria.toUpperCase() != "UNIDADE" ? "S" : "") + (route.params.categoria.toUpperCase() == "LINK" ? " " + t('uteis').toUpperCase() : "")}</Text>
            {/* Destaques */}
            { route.params.categoria.toUpperCase() != "LINK" && conteudoDestaque.length > 0 &&
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 10 }}
                data={conteudoDestaque}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo destaque vertical conteudo={item} cliente={cliente} />
                )}
            />
            }
            {/* Blocos */}
            { route.params.categoria.toUpperCase() != "LINK" &&
            <FlatList
                show={route.params.categoria.toUpperCase()!="LINK"}
                numColumns={ numColumns }
                style={{ padding: 10 }}
                data={conteudoBlocos}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo bloco conteudo={item} cliente={cliente} />
                )}
                ListEmptyComponent={() => 
                    !conteudoDestaque.length && <SemConteudo text={t('MsgCategoriaSemConteudo')} />
                }
            />
            }
            {/* Link */}
            { route.params.categoria.toUpperCase() == "LINK" &&
            <FlatList
                show={route.params.categoria.toUpperCase()=="LINK"}
                numColumns={1}
                style={{ padding: 10 }}
                data={conteudoBlocos}
                keyExtractor={item => item.id.toString()}
                renderItem={({ item }) => (
                    <Conteudo link conteudo={item} cliente={cliente} />
                )}
                ListEmptyComponent={() => 
                    conteudoDestaque.length==0 && <SemConteudo text={t('MsgSemLinkCadastrado')} />
                }
            />
            }
            <Copyright />
        </ScrollView>
    </>
    );
}