import React, { useState, useEffect } from 'react';
//import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useRoute, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, Conteudo, Comentarios, Copyright } from '../components/Elements';
import { Menu } from '../components/Menu';

import { ApiPost } from '../services/ApiServices';

export default function CategoriaConteudo() {

    //const [ t, i18n ] = useTranslation();

    const route = useRoute();
    const isFocused = useIsFocused();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ conteudo, setConteudo ] = useState(null);

    useEffect(() => {

        //i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {
        if (isFocused) {
            setConteudo(route.params.conteudo);
            setLoading(false);
            ApiPost("LogUso", "acessou|" + route.params.conteudo.id);
        }
    }, [isFocused]);

    return(
    <>
        <Loading show={loading} />
        <ScrollView style={{ height: 1 }}>
            <Menu cliente={cliente} categoria={conteudo?.tipo} />
            { conteudo!=null &&
            <Conteudo conteudo={conteudo} cliente={cliente} />
            }
            { conteudo!=null && cliente.comentarios && ['Notícia','Vídeo','Podcast'].includes(conteudo?.tipo) &&
            <Comentarios conteudo={conteudo} cliente={cliente} />
            }
            <Copyright />
        </ScrollView>
    </>
    );
}