import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import HTML from "react-native-render-html";
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Back, Title } from '../components/Elements';
import { ApiGet } from '../services/ApiServices';

export default function Cadastro() {

    const [ t, i18n ] = useTranslation();

    const [ cliente, setCliente ] = useState({});
    const [ htmlContent, setHtmlContent ] = useState('...');

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {

        if (cliente.token) {
            ApiGet('cadastro/' + cliente.token).then((res) => {
                setHtmlContent(res)
            });
        }

    }, [cliente]);

    return (<>
        <ScrollView style={{ flex: 1, padding: 20 }}>
            <Back />
            <Title style={{ color: cliente.cor1, textAlign: 'center', marginTop: 10 }}>{t('NaoPossuiCadastro').toUpperCase()}</Title>
            <HTML source={{ html: htmlContent }} />
        </ScrollView>
    </>);
}