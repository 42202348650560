import React, { useState, useEffect, useRef } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { Text, TouchableOpacity, StyleSheet, FlatList, Pressable } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'modal-enhanced-react-native-web';
import { FontAwesome } from '@expo/vector-icons';

import { BuscaBar } from './Elements';

import { ApiGet, ApiPost } from '../services/ApiServices';

export function Menu(props) {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();
    const isFocused = useIsFocused();

    const flatList = useRef(null);

    const [ mostraBusca, setMostraBusca ] = useState(false);
    const [ categorias, setCategorias ] = useState([
        { tit: t('Procurar').toUpperCase(), show: false, nav: "modal:busca;", param: null },
        { tit: t('Feed').toUpperCase(), show: false, nav: "Home", param: "" },
        { tit: t('TvOnline').toUpperCase(), show: false, nav: "TVOnline", param: props.cliente.url_tvonline },
        { tit: t('Unidade').toUpperCase(), show: false, nav: "Categoria", param: "Unidade" },
        { tit: t('LinksUteis').toUpperCase(), show: false, nav: "Categoria", param: "Link" },
        { tit: t('Noticias').toUpperCase(), show: false, nav: "Categoria", param: "Notícia" },
        { tit: t('Videos').toUpperCase(), show: false, nav: "Categoria", param: "Vídeo" },
        { tit: t('Podcasts').toUpperCase(), show: false, nav: "Categoria", param: "Podcast" },
        { tit: t('Downloads').toUpperCase(), show: false, nav: "Categoria", param: "Download" },
    ]);

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
        renderMenu();
        checkActive();
    }, []);

    useEffect(() => {
        if (isFocused) {
            renderMenu();
        }
    }, [isFocused]);

    let renderMenu = () => {
        ApiGet('categorias').then((catR) => {
            var catL = [...categorias];
            //--
            catL[0].tit = t('Procurar').toUpperCase();
            catL[1].tit = t('Feed').toUpperCase();
            catL[2].tit = t('TvOnline').toUpperCase();
            catL[3].tit = t('Unidade').toUpperCase();
            catL[4].tit = t('LinksUteis').toUpperCase();
            catL[5].tit = t('Noticias').toUpperCase();
            catL[6].tit = t('Videos').toUpperCase();
            catL[7].tit = t('Podcasts').toUpperCase();
            catL[8].tit = t('Downloads').toUpperCase();
            //--
            catL[0].show = true;
            catL[1].show = true;
            catL[2].show = catR.includes("TVONLINE");
            catL[3].show = catR.includes("UNIDADE");
            catL[4].show = catR.includes("LINKS");
            catL[5].show = catR.includes("NOTÍCIAS");
            catL[6].show = catR.includes("VÍDEOS");
            catL[7].show = catR.includes("PODCASTS");
            catL[8].show = catR.includes("DOWNLOADS");
            //--
            catL[0].show = (catL[3].show || catL[4].show || catL[5].show || catL[6].show || catL[7].show || catL[8].show);
            catL[1].show = (catL[3].show || catL[4].show || catL[5].show || catL[6].show || catL[7].show || catL[8].show);
            //--
            setCategorias(catL);
            flatList.current?.scrollToIndex({ index: categorias.map(obj => obj.param).indexOf(props.categoria) || 0, animated: false });
        });
        scrollTo(categorias.map(obj => obj.param).indexOf(props.categoria) || 0);
    }

    let checkActive = () => {
        ApiPost('usuario', {}).then((ok) => {
            if (!ok) {
                //-- logout
                AsyncStorage.removeItem('usuario').then(() => {
                    navigation.replace('AuthFlow', { screen: 'Login'});
                });
            }
        });
    }

    let scrollTo = (itemIndex) => {
        setTimeout(() => {
            flatList.current?.scrollToIndex({ index: itemIndex > 2 ? itemIndex : 0 });
        }, 500);
    }

    let goTo = (nav, param) => {
        if (!nav) console.log('reload?')
        //else if (nav.startsWith('http')) Linking.openURL(nav) OR window.open('')
        else if (nav.startsWith('modal:busca')) setMostraBusca(!mostraBusca)
        else navigation.push(nav, { categoria: param })
    }

    return(
        <>
            <FlatList
                ref={flatList}
                horizontal
                style={{ paddingHorizontal: 10, paddingVertical: 20 }}
                data={categorias.filter(cat => cat.show==true)}
                keyExtractor={item => item.tit}
                // initialScrollIndex={categorias.map(obj => obj.param).indexOf(props.categoria) || 0}
                onScrollToIndexFailed={info => scrollTo(info.index)}
                renderItem={({ item, index }) => (
                    <TouchableOpacity id={item.tit}
                        style={[styles.btnCat, { backgroundColor: index == 0 ? '#ececec' : item.param==props.categoria ? props.cliente.cor1 : props.cliente.cor1+'26' }]}
                        onPress={() => goTo(item.nav, item.param) }
                        >
                        {index == 0 ? 
                            <FontAwesome name="search" style={{marginTop: 2}} /> 
                            :
                            <Text style={[styles.btnCatTit, {color: index == 0 ? '#999' : item.param==props.categoria ? '#FFF' : props.cliente.cor1 }]}>{item.tit}</Text>
                        }
                    </TouchableOpacity>
                )}
            />
            <Modal
                animationType="slide"
                animationIn="slideInLeft"
                animationOut="slideOutRight"
                transparent={true}
                onSwipe={() => setMostraBusca(!mostraBusca)}
                swipeDirection="up"
                visible={mostraBusca}
                style={{ margin: 0 }}
                >
                <Pressable style={styles.modalContainer} onPress={()=>setMostraBusca(!mostraBusca)}>
                    <Pressable style={styles.modalView} onPress={()=>{}}>
                        <BuscaBar onPress={()=>setMostraBusca(false)} />
                    </Pressable>
                </Pressable>
            </Modal>
        </>
    )

}

const styles = StyleSheet.create({

    btnCat: {
        flex: 1,
        flexDirection: 'row',
        marginRight: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        paddingBottom: 7,
        borderRadius: 6,
    },
    btnCatTit: {
        color: '#FFF',
        fontWeight: '700',
        fontSize: 12,
        // marginLeft: 5,
    },

    modalContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        marginTop: 80,
        marginHorizontal: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },

});