import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { StyleSheet, View, FlatList, TouchableOpacity, Text } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Title, Copyright, Button } from '../components/Elements';

export default function Config() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();
    const [ cliente, setCliente ] = useState({});

    const menu = [
        { tit: t('MeuCadastro').toUpperCase(), nav: "MeuCadastro", icon: <EvilIcons name="user" size={90} color={'#fff'} /> },
        { tit: t('MinhaSenha').toUpperCase(), nav: "MinhaSenha", icon: <EvilIcons name="lock" size={90} color={'#fff'} /> },
        { tit: t('FaleConosco').toUpperCase(), nav: "FaleConosco", icon: <EvilIcons name="envelope" size={90} color={'#fff'} /> },
        { tit: t('TermosUso').toUpperCase(), nav: "Termos", icon: <EvilIcons name="question" size={90} color={'#fff'} /> },
    ];

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    let logout = () => {
        AsyncStorage.removeItem('usuario').then(() => {
            //navigation.popToTop();
            //navigation.navigate('AuthFlow', { screen: 'Login'});
            navigation.replace('AuthFlow', { screen: 'Login'});
        });
    }

    return(
        <View style={{ flex: 1 }}>
            <Title style={{ color: cliente.cor1, padding: 15, fontSize: 30 }}>{t('Configuracoes').toUpperCase()}</Title>
            <FlatList
                numColumns={2}
                style={{ padding: 15 }}
                data={menu}
                keyExtractor={item => item.tit}
                renderItem={({ item }) => (
                    <TouchableOpacity 
                        style={{ flex:1, padding: 15, backgroundColor: cliente.cor3, margin: 5, borderRadius: 5 }}
                        onPress={() => navigation.push(item.nav) }
                        >
                        <View style={{ textAlign: 'center' }}>{item.icon}</View>
                        <Text style={{color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>{item.tit}</Text>
                    </TouchableOpacity>
                )}
            />
            <Button
                onPress={() => logout()}
                text={<Text>{t('Logout').toUpperCase()} <EvilIcons name="minus" /></Text>}
                style={{ backgroundColor: cliente.cor1, margin: 10 }}
            />
            <Copyright />
        </View>
    );
}

const styles = StyleSheet.create({
    btnCat: {
        flex: 1,
        flexDirection: 'row',
        marginRight: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        paddingBottom: 7,
        borderRadius: 6,
    },
    btnCatTit: {
        color: '#FFF',
        fontWeight: '700',
        fontSize: 12,
        // marginLeft: 5,
    },
    btnCatIcon: {
        marginTop: 2
    },
});