import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native';

export function HeaderTitle(props) {
    const navigation = useNavigation();
    return (
        <View style={styles.title}>
            { !props.backButton ?
            <Image source={{uri: props.cliente?.icone || '//farolapp.com.br/app_assets/pwa-default/icone.png'}} resizeMode="contain" style={{ width:30, height:30 }} />
            :
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <Ionicons name="arrow-back" size={30} color={props.cliente.cor1} style={{ marginTop: 1 }}/>
            </TouchableOpacity>
            }
            <Text style={{color: props.cliente?.cor1 || '#999', fontWeight: '500', fontSize: 16, marginTop: 5, marginLeft: 10}}>{props.cliente.nome || "FarolApp"}</Text>
        </View>
    )
};

export function ConfigButton() {
    const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => navigation.push('Config')}>
            <Ionicons name="settings" size={20} color="#999" style={{ padding: 15 }} />
        </TouchableOpacity>
    )
};

export function NotificationButton(props) {
    const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => navigation.push('Notificacoes')}>
            <Ionicons name="notifications" size={20} color="#999" style={{ padding: 15, paddingRight: props.spl ? 0 : 15 }} />
        </TouchableOpacity>
    )
};

export function BookmarkButton(props) {
    const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => navigation.push('Favoritos')}>
            <Ionicons name="bookmark" size={20} color="#999" style={{ padding: 15, paddingRight: props.spl ? 0 : 15 }} />
        </TouchableOpacity>
    )
};

export function AddHomeScreenButton(props) {
    return (
        <TouchableOpacity onPress={() => { if (deferredPrompt !== undefined) deferredPrompt.prompt(); } }>
            <Ionicons name="download-outline" size={20} color="#999" style={{ padding: 15, paddingRight: props.spl ? 0 : 15 }} />
        </TouchableOpacity>
    )
};

export function BookmarkNotificationConfigButtons() {
    //const navigation = useNavigation();
    return (
        <View style={{ flexDirection:'row' }}>
            { deferredPrompt !== undefined &&
            <AddHomeScreenButton spl={true} />
            }
            <BookmarkButton spl={true} />
            <NotificationButton spl={true} />
            <ConfigButton />
        </View>
    )
};

const styles = StyleSheet.create({

    title: {
        flex: 1,
        flexDirection: 'row',
        padding: 15,
        //alignItems: 'center',
        //width: 225, //???
    },
    nome: {
        color: '#fff',
        paddingLeft: 20,
        paddingBottom: 5,
    },

    container: {
        flex: 1,
        backgroundColor: '#678f47',
        padding: 0,
        borderWidth: 0,
    },
    header: {
        flex: 1,
        marginTop: -4,
        padding: 20,
        paddingTop: 30,
        paddingBottom: 30,
        color: '#fff',
        backgroundColor: '#4f7a43',       
    },
    content: {
        flex: 10,
    },
    navItemG: {
        flex: 1,       
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: 20,
        marginBottom: 0,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    },
    navItemP: {
        margin: 20,
        marginBottom: 0,
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        padding: 20,
        paddingBottom: 0,
        color: '#fff',
        backgroundColor: '#7fa549',
    },
    white: {
        color: '#fff',
    },
    muted: {
        color: 'rgba(255, 255, 255, 0.35)'
    },
    small: {
        fontSize: 13,
    },

});