import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Alert from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Title, Loading, Copyright, InputIcon, Button, PasswordStrength } from '../components/Elements';

import { ApiPut } from '../services/ApiServices';

export default function MinhaSenha() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();
    const [ cliente, setCliente ] = useState({});

    const [ loading, setLoading ] = useState(true);
    const [ form, setForm ] = useState({
        senha_atual: '',
        senha_nova: '',
        senha_confirma: '',
    });
    const [ hideSenha1, setHideSenha1 ] = useState(true);
    const [ hideSenha2, setHideSenha2 ] = useState(true);
    const [ hideSenha3, setHideSenha3 ] = useState(true);

    let refs = {};

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

        setLoading(false);
        
    }, []);

    let atualizarSenha = () => {
        if (!form.senha_atual || !form.senha_nova || !form.senha_confirma) {
            Alert.fire(t('Atencao'), t('MsgPreencha3CamposSenha'));
            return;
        }
        var expReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        if (!form.senha_nova.match(expReg)) {
           Alert.fire(t('Atencao'), t('MsgSenhaRequisitos'));
            return;
        }
        if (form.senha_nova != form.senha_confirma) {
            Alert.fire(t('Atencao'), t('MsgConfirmacaoSenhaDiferente'));
            return;
        }
        setLoading(true);
        ApiPut('AlteraSenha', form).then(res => {
            if (res) {
                Alert.fire(res);
                navigation.navigate('Config');
            };
        }).finally(() => {
            setLoading(false);
        });
    }

    let updateForm = (field,value) => {
        setForm({...form, [field]: value});
    };

    return(
        <ScrollView style={{ padding: 15 }}>
            <Title style={{ color: cliente.cor1, fontSize: 30, marginBottom: 10 }}>{t('AlterarSenha').toUpperCase()}</Title>

            <Text style={styles.label}>{t('SenhaAtual')}:</Text>
            <InputIcon
                autoCompleteType={'off'}
                value={form.senha_atual}
                secureTextEntry={hideSenha1}
                ref={(ref) => refs.senha_atual = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("senha_atual",val)}
                onSubmitEditing={() => refs.senha_nova.focus()}
                icon={hideSenha1 ? "eye" : "eye-off"}
                onPressIcon={()=>setHideSenha1(!hideSenha1)}
            />

            <Text style={styles.label}>{t('NovaSenha')}: *</Text>
            <InputIcon
                autoCompleteType={'off'}
                value={form.senha_nova}
                secureTextEntry={hideSenha2}
                ref={(ref) => refs.senha_nova = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("senha_nova",val)}
                onSubmitEditing={() => refs.senha_confirma.focus()}
                icon={hideSenha2 ? "eye" : "eye-off"}
                onPressIcon={()=>setHideSenha2(!hideSenha2)}
            />
            
            <Text style={styles.label}>{t('ConfirmacaoNovaSenha')}:</Text>
            <InputIcon
                autoCompleteType={'off'}
                value={form.senha_confirma}
                secureTextEntry={hideSenha3}
                ref={(ref) => refs.senha_confirma = ref}
                returnKeyType={'go'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("senha_confirma",val)}
                onSubmitEditing={() => Keyboard.dismiss()}
                icon={hideSenha3 ? "eye" : "eye-off"}
                onPressIcon={()=>setHideSenha3(!hideSenha3)}
            />

            <PasswordStrength senha={form.senha_nova}></PasswordStrength>

            <Button text={t('AlterarSenha')} style={{marginTop: 30, backgroundColor: cliente.cor1 }}
                onPress={ () => atualizarSenha() }
            />
            
            <Copyright />
            <Loading show={loading} />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    label: {
        fontSize: 12,
        marginBottom: 3,
    }
});