import React, { useState, useRef, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { ScrollView, View, ImageBackground, TouchableOpacity, Dimensions, ActivityIndicator, Text, TextInput, StyleSheet, Pressable, FlatList } from 'react-native';
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons'; 
import HTML from "react-native-render-html";
import Iframe from 'react-iframe';
import ReactHlsPlayer from 'react-hls-player';
import * as WebBrowser from 'expo-web-browser';
import { useNavigation } from '@react-navigation/native';
import EmojiSelector, { Categories } from 'react-native-emoji-selector';
import { SpecialScrollView, SpecialView } from 'react-native-scroll-to-element';

import packageJson from '../../package.json';

import { ApiPost, ApiGet } from '../services/ApiServices';

const windowWidth = Dimensions.get('window').width;
const imageMaxWidth = Math.min(windowWidth, windowWidth >= 1024 ? 800 : 400);
const imageMaxHeight = Math.min(Math.round(imageMaxWidth / 4 * 3), windowWidth >= 1024 ? 600 : 300);
const numColumns = Math.max(2,parseInt(Math.ceil(windowWidth / 360)));
const imageColumWidth = parseInt(windowWidth / numColumns) - 15;
const imageColumHeight = parseInt(Math.round(imageColumWidth / 4 * 3));

export function Loading(props) {
    if (!props.show) return null;
    return (
        <ActivityIndicator animating={true} style={styles.ldn} size="large" color="#999" />
    )
};

export function Title(props) {
    return (
        <Text style={[ { fontSize:22 }, props.style ]}>{props.children}</Text>
    )
};

export const Input = React.forwardRef((props, ref) => {
    return (
        <TextInput {...props} style={[styles.ipt, styles.sombra, props.style]} ref={ref}
            onChangeText={(value) => props.onChangeText(value)}
            onSubmitEditing={() => props.onSubmitEditing()} 
        />
    )
});

export const InputIcon = React.forwardRef((props, ref) => {
    return (
        <View style={[styles.ipt, styles.sombra, props.style, {flex: 1, flexDirection: 'row', maxHeight: 43, padding: 0}]}>
            <TextInput {...props} style={[styles.ipt, {flex: 1, marginBottom: 0}]} ref={ref} 
                onChangeText={(value) => props.onChangeText(value)}
                onSubmitEditing={() => props.onSubmitEditing()} 
            />
            <TouchableOpacity style={{padding: 12}} onPress={() => props.onPressIcon()}>
                <Feather name={props.icon} size={18} style={{ color:'#999' }}/>
            </TouchableOpacity>
        </View>
    )
});

export const SearchBar = React.forwardRef((props, ref) => {
    const [ t, i18n ] = useTranslation();
    const [ query, setQuery ] = useState('');
    const [ showClear, setShowClear ] = useState(false);
    const inputRef = useRef(ref);
    // let clear = () => {
    //     setShowClear(false);
    //     inputRef.current.blur();
    //     inputRef.current.clear();
    //     props.onChangeText(inputRef.current.value);
    // }
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
    }, []);
    return (
        <View style={{ flex:1, flexDirection:'row', backgroundColor: '#f5f5f5', borderRadius: 10, marginTop: 5, marginBottom: 10 }}>
            <TextInput style={{ flex:1, paddingLeft: 10 }} returnKeyType={'go'} placeholder={t('ProcurarConteudo').toUpperCase()} value={props.value} 
                ref={inputRef}
                defaultValue={props.text}
                onChangeText={(value) => { props.onChangeText(value); setQuery(value); setShowClear(value.length > 0) }}
            />
            {/*
            { showClear &&
            <TouchableOpacity onPress={() => {clear()}}>
                <Feather name="x" size={15} style={{ padding: 10, color:'#999' }} />
            </TouchableOpacity>
            }
            */}
            <TouchableOpacity onPress={() => props.onPress(query)}>
                <Feather name="search" size={15} style={{ padding: 10, color:'#999' }}/>
            </TouchableOpacity>
        </View>
    )
});

export function Button(props) {
    return  (
        <TouchableOpacity style={[styles.btn, styles.sombra, styles[props.styleName], props.style]} onPress={ () => props.onPress() }>
            <Text style={[{color: '#fff', fontWeight:'700'}, props.styleText]}>
                { props.icon && !props.iconFamily &&
                <><Feather name={props.icon} size={18} style={{ color: '#fff', marginRight: 15 }} />{"  "}</>
                }
                { props.icon && props.iconFamily=='fontawesome' &&
                <><FontAwesome name={props.icon} size={18} style={{ color: '#fff', marginRight: 15 }} />{"  "}</>
                }
                {props.text}
            </Text>
        </TouchableOpacity>
    )
};

export function Link(props) {
    return (
        <TouchableOpacity style={[props.style]} onPress={ () => props.onPress() }>
            <Text style={[styles.lnk, props.styleText]}>{props.text}</Text>
        </TouchableOpacity>
    )
};

export function Back(props) {
    const [ t, i18n ] = useTranslation();
    const navigation = useNavigation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
    }, []);
    return  (
        <TouchableOpacity style={[styles.bck, props.style]} onPress={ () => navigation.goBack() }>
            <Text style={{ fontSize: 11 }}><Feather name="arrow-left" size={11}/> {t('Voltar').toUpperCase()}</Text>
        </TouchableOpacity>
    )
};

export function BuscaBar(props) {

    const navigation = useNavigation();

    // const [ optUnidade, setOptUnidade ] = useState(props.unidade ?? true);
    // const [ optLinks, setOptLinks ] = useState(props.links ?? true);
    // const [ optNoticias, setOptNoticias ] = useState(props.noticias ?? true);
    // const [ optVideos, setOptVideos ] = useState(props.videos ?? true);
    // const [ optPodcasts, setOptPodcasts ] = useState(props.podcasts ?? true);
    // const [ optDownloads, setOptDownloads ] = useState(props.downloads ?? true);

    const search = (query) => {
        //var param = { query: query, unidade: optUnidade, links: optLinks, noticias: optNoticias, videos: optVideos, podcasts: optPodcasts, downloads: optDownloads };
        var param = { query: query };
        navigation.push('Busca', param);
    }

    return (
        <>
            <SearchBar onChangeText={()=>{}} onPress={(query)=>{ search(query); props.onPress(); }} text={props.text} />
            {/* <View style={{ flexDirection: 'row'}}>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optUnidade} onValueChange={(checked)=>setOptUnidade(checked)} />
                    <Text style={styles.checkboxText}>UNIDADE</Text>
                </View>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optLinks} onValueChange={(checked)=>setOptLinks(checked)} />
                    <Text style={styles.checkboxText}>LINKS</Text>
                </View>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optNoticias} onValueChange={(checked)=>setOptNoticias(checked)} />
                    <Text style={styles.checkboxText}>NOTÍCIAS</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row'}}>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optVideos} onValueChange={(checked)=>setOptVideos(checked)} />
                    <Text style={styles.checkboxText}>VÍDEOS</Text>
                </View>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optPodcasts} onValueChange={(checked)=>setOptPodcasts(checked)} />
                    <Text style={styles.checkboxText}>PODCASTS</Text>
                </View>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={styles.checkboxControl} color={'#ccc'} value={optDownloads} onValueChange={(checked)=>setOptDownloads(checked)} />
                    <Text style={styles.checkboxText}>DOWNLOADS</Text>
                </View>
            </View> */}
        </>
    )
}

export function Conteudo(props) {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();

    const [ conteudo, setConteudo ] = useState(props.conteudo);
    const [ curtido, setCurtido ] = useState(props.conteudo.curtido);
    const [ favoritado, setFavoritado ] = useState(props.conteudo.favoritado);

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
    }, []);

    // let assistiu = (msg) => {
    //     console.log("postMessage", msg + " (" + conteudo.id + ")");
    //     if (msg == "played") {
    //         ApiPost("LogUso", "assistiu|" + conteudo.id);
    //     }
    // }

    let curtiu = () => {
        var curtiu = !curtido;
        setCurtido(curtiu);
        ApiPost("ConteudoCurtir", { id_conteudo: conteudo.id, curtiu: curtiu});
    }

    let favoritou = () => {
        var favoritou = !favoritado;
        setFavoritado(favoritou);
        ApiPost("ConteudoFavoritar", { id_conteudo: conteudo.id, favoritou: favoritou});
    }

    let download = async () => {
        await WebBrowser.openBrowserAsync(conteudo.arquivo);
        ApiPost("LogUso", "baixou|" + conteudo.id);
    }

    return (
        (props.destaque || props.destaqueH) ?
        <Pressable onPress={ () => navigation.push('Conteudo', { conteudo: conteudo }) }>
            <ImageBackground 
                source={{ uri: conteudo.imagem }}
                style={[styles.cont, (props.destaque ? styles.destaque : styles.destaqueH), props.style, props.vertical ? { marginBottom: 10 } : {}]}
                imageStyle={{ borderRadius: 6}}
                >
                { conteudo.tags.length>0 &&
                <Text style={[styles.sombra, styles.tag, { backgroundColor: props.cliente.cor2 }]}>
                    {conteudo.tags.split(',')[0].toUpperCase()}
                </Text>
                }
                <View  style={styles.bgTransparente}>
                    <Text style={[styles.sombraTexto, { color: '#fff', fontSize: 18, fontWeight: '500', padding: 10, paddingBottom: 0, borderRadius: 6 }]}>
                        {conteudo.titulo}
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                        <Text style={[styles.sombraTexto, { color: '#ccc', fontSize: 10, fontWeight: '500', padding: 10, paddingTop: 5 }]}>
                            {conteudo.data_publicacao}
                        </Text>
                        <Text style={[styles.sombraTexto, { color: '#ccc', fontSize: 10, fontWeight: '500', padding: 12, paddingTop: 5 }]}>
                            {conteudo.likes} {t('Curtidas').toLowerCase()}
                        </Text>
                    </View>
                </View>
            </ImageBackground>
        </Pressable>
        :
        props.bloco ?
        <Pressable onPress={ () => navigation.push('Conteudo', { conteudo: conteudo }) }
            style={{ flexDirection: 'col', marginBottom: 20, width: (windowWidth / numColumns) - 15, marginRight: 10 }}
            >
            <ImageBackground 
                source={{ uri: conteudo.imagem }}
                style={[styles.cont, styles.bloco, props.style]}
                imageStyle={{ borderRadius: 6 }}
                >
                { conteudo.tags.length>0 &&
                <Text style={[styles.sombra, styles.tag, { backgroundColor: props.cliente.cor2, marginBottom: -8 }]}>
                    {conteudo.tags.split(',')[0].toUpperCase()}
                </Text>
                }
            </ImageBackground>
            <View style={{ justifyContent: 'space-between' }}>
                <View>
                    <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                        {conteudo.data_publicacao}
                    </Text>
                    <Text style={{ color: '#333', fontSize: 18, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                        {conteudo.titulo}
                    </Text>
                </View>
                <View>
                    <Text style={{ color: '#999', fontSize: 12, fontWeight: '500', paddingHorizontal: 10 }}>
                        {conteudo.likes} {t('Curtidas').toLowerCase()}
                    </Text>
                </View>
            </View>
        </Pressable>
        :
        props.link ?
        <Pressable onPress={ () => window.open(conteudo.link, '_system') }
            style={{ flexDirection: 'row', marginBottom: 15 }}
            >
            <View style={{ flex: 1, justifyContent: 'space-between', paddingHorizontal: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
                <View>
                    <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', paddingBottom: 2 }}>
                        {conteudo.data_publicacao}
                    </Text>
                    <Text style={{ color: '#333', fontSize: 18, fontWeight: '500', paddingBottom: 2 }}>
                        {conteudo.titulo}
                    </Text>
                    <Text style={{ color: '#999', fontSize: 12, fontWeight: '500', paddingBottom: 15 }}>
                        {conteudo.link}
                    </Text>
                </View>
            </View>
        </Pressable>
        :
        props.lista ?
        <Pressable onPress={ () => navigation.push('Conteudo', { conteudo: conteudo }) }
            style={{ flexDirection: 'row', marginBottom: 20 }}
            >
            <ImageBackground 
                source={{ uri: conteudo.imagem }}
                style={[styles.cont, styles.lista, props.style]}
                imageStyle={{ borderRadius: 6 }}
                >
                { conteudo.tags.length>0 &&
                <Text style={[styles.sombra, styles.tag, { backgroundColor: props.cliente.cor2, marginBottom: -8 }]}>
                    {conteudo.tags.split(',')[0].toUpperCase()}
                </Text>
                }
            </ImageBackground>
            <View style={{ flex: 1, justifyContent: 'space-between' }}>
                <View>
                    <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                        {conteudo.data_publicacao}
                    </Text>
                    <Text style={{ color: '#333', fontSize: 18, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 5 }}>
                        {conteudo.titulo}
                    </Text>
                </View>
                <View>
                    <Text style={{ color: '#999', fontSize: 12, fontWeight: '500', paddingHorizontal: 10, paddingTop: 5 }}>
                        {conteudo.likes} {t('Curtidas').toLowerCase()}
                    </Text>
                </View>
            </View>
        </Pressable>
        :
        props.favorito ?
        <Pressable onPress={ () => navigation.push('Conteudo', { conteudo: conteudo }) }
            style={{ flexDirection: 'row', marginBottom: 20 }}
            >
            <ImageBackground 
                source={{ uri: conteudo.imagem }}
                style={[styles.cont, styles.favorito, props.style]}
                imageStyle={{ borderRadius: 6 }}
                >
                { conteudo.tags.length>0 &&
                <Text style={[styles.sombra, styles.tag, { backgroundColor: props.cliente.cor2, marginBottom: -8 }]}>
                    {conteudo.tags.split(',')[0].toUpperCase()}
                </Text>
                }
            </ImageBackground>
            <View style={{ flex: 1, justifyContent: 'space-between' }}>
                <View>
                    <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                        {conteudo.data_publicacao}
                    </Text>
                    <Text style={{ color: '#333', fontSize: 18, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 5 }}>
                        {conteudo.titulo}
                    </Text>
                </View>
                <View>
                    <Text style={{ color: '#999', fontSize: 12, fontWeight: '500', paddingHorizontal: 10, paddingTop: 5 }}>
                        {conteudo.likes} {t('Curtidas').toLowerCase()}
                    </Text>
                </View>
            </View>
        </Pressable>
        :
        <ScrollView>
            { conteudo.tipo == 'Notícia' || conteudo.tipo == 'Download' ?
            // <ImageBackground 
            //     source={conteudo.imagem}
            //     style={{ alignItems:'center', backgroundColor: '#333333' }}
            //     resizeMode='cover'
            //     blurRadius={15}
            //     >
            //     <img src={conteudo.imagem} style={{ maxHeight: Math.min(imageMaxHeight,400), minHeight: 300 }} />
            // </ImageBackground>
            <img src={conteudo.imagem} style={{ maxWidth:'100%', height: 'auto' }} />
            :
            <View style={[styles.cont, styles.full, props.style, {textAlign: 'center', backgroundColor: 'black', maxWidth: '100%'}]}>
                { conteudo.video.startsWith('http') ?
                <Iframe 
                    url={conteudo.video + '/' + conteudo.id} 
                    frameBorder="0" width="100%" height="100%" 
                    allowFullScreen={true} 
                    style={[styles.cont, styles.full, {flex:1, backgroundColor: 'black', borderWidth: 0}, props.style]} 
                    //onMessage={(event) => assistiu(event.nativeEvent.data)}
                />
                :
                <ReactHlsPlayer
                    src={"https://stream.mux.com/" + conteudo.video + ".m3u8"}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="100%"
                />
                }
            </View>
            }
            {/* <View style={{ flexDirection: 'column', alignItems: 'flex-start', position: 'absolute', top: 10, right: 10, paddingHorizontal: 10, backgroundColor:'rgba(255,255,255,0.33)', borderRadius: 10, zIndex: 999 }}> */}
            <TouchableOpacity onPress={() => curtiu()}  style={{ position: 'absolute', top: 10, right: 60, padding: 10, backgroundColor:'rgba(255,255,255,0.75)', borderRadius: 10, zIndex: 999 }}>
                <AntDesign name={curtido ? 'like1' : 'like2'} size={23} color={props.cliente.cor2} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => favoritou()}  style={{ position: 'absolute', top: 10, right: 10, padding: 10, backgroundColor:'rgba(255,255,255,0.75)', borderRadius: 10, zIndex: 999, paddingHorizontal: 12 }}>
                <FontAwesome name={favoritado ? 'bookmark' : 'bookmark-o'} size={24} color={props.cliente.cor2} />
            </TouchableOpacity>
            {/* </View> */}
            { conteudo.tags.length>0 &&
            <Text style={[styles.sombra, styles.tag, { backgroundColor: props.cliente.cor2, marginTop: -12, zIndex: 999 }]}>
                {conteudo.tags.split(',')[0].toUpperCase()}
            </Text>
            }
            <View style={{ padding: 10, paddingTop: 0 }}>
                <Text style={{ color: '#CCC', fontSize: 12, fontWeight: '500', textAlign: 'right' }}>
                    {conteudo.data_publicacao}
                </Text>
                <Text style={{ color: '#333', fontSize: 24, fontWeight: '500', marginBottom: 10 }}>
                    {conteudo.titulo}
                </Text>
                { conteudo.tipo == 'Download' &&
                <Button text='Download' style={{marginVertical: 20, backgroundColor: props.cliente.cor1 }}
                    onPress={ () => download() }
                />
                }
                <HTML source={{ html: conteudo.descricao }}
                    style={{ color: '#666', fontSize: 18, fontWeight: '500', textAlign: 'justify' }}
                />
                { conteudo.galeria.length>0 && 
                <View>
                    <Text style={{ color: '#333', fontSize: 16, fontWeight: '500', marginVertical: 10 }}>
                    {t('GaleriaImagens')}
                    </Text>
                    <View>
                        { conteudo.galeria.map((imagem) =>
                        <img src={imagem} style={{ width:'100%', marginBottom: 5 }} />
                        )}
                    </View>
                </View>
                }
                <Text style={{ color: '#333', fontSize: 20, fontWeight: '500', textAlign: 'center', marginVertical: 10 }}>
                    {conteudo.likes} {t('Curtidas').toLowerCase()}
                </Text>
                { conteudo.tags.length>0 &&
                <Text style={{ color: '#999', fontSize: 10, fontWeight: '500', textAlign: 'center', marginHorizontal: 5 }}>
                { conteudo.tags.split(',').map((tag) => '#' + tag + ' ')}
                </Text>
                }
            </View>
            
        </ScrollView>
    )
};

export function Comentarios(props) {
    const [ t, i18n ] = useTranslation();
    const [ conteudo, setConteudo ] = useState(props.conteudo);
    const [ comentarios, setComentarios ] = useState([]);
    const [ comentario, setComentario ] = useState('');
    const [ showEmojis, setShowEmojis ] = useState(false);
    const viewRef = useRef();
   
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
        ApiGet('Comentarios/' + props.conteudo.id).then((res) => {
            setComentarios(res);
            //setShowEmojis(false);
        });
    }, []);

    useEffect(() => {
        if (showEmojis) viewRef.current.focus();
    }, [showEmojis]);

    let comentar = () => {
        if (comentario.length > 1) {
            ApiPost("Comentarios", { id_conteudo: conteudo.id, comentario: comentario}).then((res) => {
                setComentarios(res);
                setComentario('');
                setShowEmojis(false);
            });
        }
    }

    let addEmoji = (emoji) => {
        setComentario(comentario + '' + emoji);
    }

    return (
        <SpecialScrollView>
            <Title style={{ padding: 10, fontSize: 20 }}>{t('Comentarios').toUpperCase()}:</Title>
            <FlatList
                //scrollEnabled={false}
                style={{ padding: 0 }}
                data={comentarios}
                renderItem={({ item, index }) => (
                    <View key={index} style={{ margin:10, marginTop: 0, padding: 10, borderWidth: 1, borderColor: '#f5f5f5', }}>
                        <Text style={{ color: '#999', fontSize: 11, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 2 }}>
                            {item.data_hora} - {item.nome_usuario}
                        </Text>
                        <Text style={{ color: item.bloqueado ? '#C0C0C0' : '#333', fontSize: 13, fontWeight: '500', paddingHorizontal: 10, paddingBottom: 5, fontStyle: item.bloqueado ? 'italic' : 'normal' }}>
                            {item.comentario}
                        </Text>
                    </View>
                )}
                ListEmptyComponent={() => 
                    <SemConteudo text={t('ConteudoNaoTemComentarios')} style={{ paddingHorizontal: 10, paddingVertical: 0, textAlign: 'left' }} />
                }
            />
            <View style={{ padding: 10 }}>
                <Input value={comentario} placeholder={t('NovoComentario')} multiline={true} style={{height: 85, textAlignVertical: 'top', backgroundColor: '#F5F5F5', borderColor: '#E8E8E8', borderWidth: 1 }}
                    //ref={(ref) => refs.comentario = ref}
                    returnKeyType={'go'} onSubmitEditing={() => Keyboard.dismiss()} blurOnSubmit={false}
                    onChangeText={(val) => setComentario(val)}
                />
                <View style={{flexDirection:'row', marginBottom: 10}}>
                    <Button text={t('EnviarComentario')} style={{backgroundColor: props.cliente.cor1, flex: 1 }}
                        onPress={ () => comentar() }
                    />
                    <Button text="😃" style={{backgroundColor: '#e5e5e5', width: 45, marginLeft: 5 }}
                        onPress={ () => setShowEmojis(!showEmojis) }
                    />
                </View>
                { showEmojis &&
                <SpecialView ref={viewRef}>
                    <EmojiSelector 
                        onEmojiSelected={emoji => addEmoji(emoji)} 
                        columns={12} 
                        category={Categories.emotion} 
                        showSectionTitles={false}
                        showHistory={false}
                        showTabs={false}
                        theme={props.cliente.cor1}
                        placeholder={t('ProcurarEmoji')}
                        style={{ minHeight: 200 }}
                    />
                </SpecialView>
                }
            </View>
        </SpecialScrollView>
    )
};

export function SemConteudo(props) {

    return (
        <Text style={{ color: '#999', paddingHorizontal: '20%', paddingVertical: '50%', textAlign: 'center', fontSize: 16, fontStyle: 'italic', ...props.style }}>
            {props.text}
        </Text>
    )
};

export function Copyright() {

    return (
        <Pressable style={{ color: '#ccc', padding: 20, paddingBottom: 30, textAlign: 'center', fontSize: 11 }} onLongPress={() => window.location.reload(true) }>
            &copy; Farol Sign - v{packageJson.version}
        </Pressable>
    )
};

export function PasswordStrength(props) {
    const [ t, i18n ] = useTranslation();
    const minCharRegex = new RegExp("^(?=.{6,})");
    const numberRegex = new RegExp("^(?=.*[0-9])");
    const lowerCaseRegex = new RegExp("^(?=.*[a-z])");
    const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('idioma'));
    }, []);
    return (
        <View style={{ flexDirection: 'column', fontSize: 10 }}>
            <Text>{t('SuaSenhaDeveTer')}</Text>
            <Text>
                {minCharRegex.test(props.senha) ?
                <AntDesign name="checkcircle" size={10} color="darkgreen" /> : <AntDesign name="closecircleo" size={10} color="darkred" />
                } {t('Minimo6Caracteres')};
            </Text>
            <Text>
                {numberRegex.test(props.senha) ?
                <AntDesign name="checkcircle" size={10} color="darkgreen" /> : <AntDesign name="closecircleo" size={10} color="darkred" />
                } {t('Minimo1Numero')};
            </Text>
            <Text>
                {lowerCaseRegex.test(props.senha) ?
                <AntDesign name="checkcircle" size={10} color="darkgreen" /> : <AntDesign name="closecircleo" size={10} color="darkred" />
                } {t('Minimo1LetraMinuscula')};
            </Text>
            <Text>
                {upperCaseRegex.test(props.senha) ?
                <AntDesign name="checkcircle" size={10} color="darkgreen" /> : <AntDesign name="closecircleo" size={10} color="darkred" />
                } {t('Minimo1LetraMaiuscula')}.
            </Text>
        </View>
    )
};



const styles = StyleSheet.create({
    ldn: {
        backgroundColor: 'rgba(255,255,255,0.75)',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        elevation: 9999,
    },
    ipt: {
        alignSelf: 'stretch',
        padding: 12,
        marginBottom: 10,
        backgroundColor: '#f8f8f8',
        borderRadius: 5,
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 2,
        //     height: 2,
        // },
        // shadowOpacity: 0.2,
        // shadowRadius: 3,
    },
    btn: {
        //flexDirection: 'row',
        alignSelf: 'stretch',
        //marginTop: 15,
        padding: 12,
        alignItems: 'center',
        color: '#fff',
        fontWeight: "bold",
        backgroundColor: '#ccc',
        borderRadius: 5,
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 2,
        //     height: 2,
        // },
        // shadowOpacity: 0.2,
        // shadowRadius: 3,
    },
    bck: {
        // position: 'absolute',
        // zIndex: 9999,
        // left: 10,
        // top: 10,
        //justifyContent: 'center',
        borderRadius: 50,
        padding: 8,
        elevation: 1,
        width: 80,
        height: 30,
        backgroundColor: '#e0e0e0',
    },
    // primary: {
    //     backgroundColor: '#a08b71',
    //     borderColor: '#857159',
    // },
    // secondary: {
    //     backgroundColor: '#CCC',
    //     borderColor: '#999',
    // },
    // light: {
    //     backgroundColor: '#FFFFFF',
    //     borderColor: '#a08b71',
    // },
    lnk: {
        color: '#857159',
        //textDecorationLine: 'underline',
    },
    lst: {
        //flex:1,
        flexDirection: 'row',
        justifyContent: 'space-between',        
        backgroundColor: '#fff',
        padding: 10,
    },
    cont: {
        backgroundColor: '#e8e8e8',
        maxWidth: imageMaxWidth,
        maxHeight: imageMaxHeight,
    },
    destaque: {
        justifyContent: 'space-between',
        width: imageMaxWidth - 20,
        height: imageMaxHeight - 15,
        marginRight: 10,
        borderRadius: 5,
    },
    destaqueH: {
        justifyContent: 'space-between',
        width: imageMaxWidth - 40,
        height: imageMaxHeight - 30,
        marginRight: 10,
        borderRadius: 5,
    },
    bloco: {
        width: imageColumWidth,
        height: imageColumHeight,
        justifyContent: 'flex-end',
        marginBottom: 15,
    },
    lista: {
        width: imageMaxWidth * 0.40, 
        height: imageMaxHeight * 0.40, //windowWidth * 0.25,
        justifyContent: 'flex-end',
    },
    favorito: {
        width: imageMaxWidth * 0.40, 
        height: imageMaxHeight * 0.40, //windowWidth * 0.25,
        justifyContent: 'flex-end',
    },
    full: {
        width: windowWidth, 
        height: imageMaxHeight,
        justifyContent: 'flex-end',
    },
    tag: {
        fontSize: 11,
        fontWeight: 700,
        color: '#fff',
        width: 'fit-content',
        borderRadius: 5,
        margin: 10,
        paddingHorizontal: 7,
        paddingVertical: 3
    },
    sombra: {
        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    sombraTexto: {
        textShadowColor: '#000',
        textShadowOffset: {width: 1, height: 1},
        textShadowRadius: 5,
    },
    bgTransparente: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: 6,
    },
    // dpd: {
    //     //flexGrow: 1,
    //     alignSelf: 'stretch',
    //     //marginTop: 15,
    //     padding: 0,
    //     borderRadius: 0,
    //     backgroundColor: '#f8f8f8',
    //     borderWidth: 1,
    //     borderColor: '#f0f0f0',
    //     elevation: 1,

    //     // flex:1,
    //     // flexDirection: 'row',
    //     // justifyContent: 'space-between',        
    //     // alignSelf:'stretch',
    //     // backgroundColor: '#fff',
    //     //padding: 20,
    //     //marginBottom: 1
    // },
    checkboxContainer: {
        flexDirection: 'row',
        width: '33%',
    },
    checkboxControl: {
        marginRight: 5,
    },
    checkboxText: {
        fontSize: 11,
        marginRight: 10,
    }
});