import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, ScrollView, View, Text, KeyboardAvoidingView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import HTML from "react-native-render-html";
import Checkbox from 'expo-checkbox';
import Alert from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Loading, InputIcon, Button, Title, Copyright, PasswordStrength } from '../components/Elements';
import { ApiGet, ApiPost } from '../services/ApiServices';

export default function Termos() {

    const [ t, i18n ] = useTranslation();

    const navigation = useNavigation();

    const [ loading, setLoading ] = useState(true);
    const [ cliente, setCliente ] = useState({});
    const [ htmlContent, setHtmlContent ] = useState('Carregando...');
    const [ aceite, setAceite ] = useState(false);
    const [ senha, setSenha ] = useState('');
    const [ confirmacao, setConfirmacao ] = useState('');
    const [ hideSenha1, setHideSenha1 ] = useState(true);
    const [ hideSenha2, setHideSenha2 ] = useState(true);

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

    }, []);

    useEffect(() => {

        if (cliente.token) {
            ApiGet('termos/' + cliente.token).then((res) => {
                setHtmlContent(res);
                setLoading(false);
            });
        }

    }, [cliente]);

    const optin = () => {

        if (!aceite) {
            Alert.fire(t('Atencao'),t('MsgProsseguirAceiteTermos'));
            return;
        }

        var expReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        if (!senha.match(expReg)) {
           Alert.fire(t('Atencao'), t('MsgSenhaRequisitos'));
            return;
        }
        if (senha != confirmacao) {
            Alert.fire(t('Atencao'), t('MsgConfirmacaoSenhaDiferente'));
            return;
        }

        setLoading(true);

        ApiPost('Termos', senha).then(res => {
            if (res) {
                navigation.replace('Home');
            };
        }).finally(() => {
            setLoading(false);
        });
    }

    return (<>
        <ScrollView contentContainerStyle={{ height: 1, padding: 10 }}>
            <Title style={{ color: cliente.cor1, textAlign: 'center', marginVertical: 10 }}>{t('AceiteTermosConfigSenha').toUpperCase()}</Title>
            <View style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5, }}>
                <HTML source={{ html: htmlContent }} />
            </View>
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
                <View style={styles.checkboxContainer}>
                    <Checkbox style={{ marginRight: 5 }} color={cliente.cor1} value={aceite} onValueChange={(checked)=>setAceite(checked)} />
                    <Text style={styles.checkboxText}>{t('LiAcordoTermosUso')}</Text>
                </View>
                <View style={[styles.checkboxContainer, { flexDirection: 'column' }]}>
                    <Text style={styles.checkboxText}>{t('DefinaConfirmeSenha')}</Text>
                    <InputIcon
                        autoCompleteType={'off'}
                        placeholder={t('NovaSenha')} 
                        value={senha || ''} 
                        secureTextEntry={hideSenha1}
                        returnKeyType={'go'} blurOnSubmit={true}
                        onSubmitEditing={() => optin()}
                        onChangeText={(val) => setSenha(val)}
                        icon={hideSenha1 ? "eye" : "eye-off"}
                        onPressIcon={()=>setHideSenha1(!hideSenha1)}
                    />
                    <InputIcon
                        autoCompleteType={'off'}
                        placeholder={t('ConfirmacaoNovaSenha')} 
                        value={confirmacao || ''} 
                        secureTextEntry={hideSenha2}
                        returnKeyType={'go'} blurOnSubmit={true}
                        onSubmitEditing={() => optin()}
                        onChangeText={(val) => setConfirmacao(val)}
                        icon={hideSenha2 ? "eye" : "eye-off"}
                        onPressIcon={()=>setHideSenha2(!hideSenha2)}
                    />
                    <PasswordStrength senha={senha}></PasswordStrength>
                </View>
                <View>

                </View>
                <Button
                    onPress={() => optin()}
                    text={<Text>{t('AceitoTermosUsoAcima').toUpperCase()}</Text>}
                    style={{ backgroundColor: cliente.cor1, marginVertical: 10 }}
                />
                <Copyright />
            </KeyboardAvoidingView>
        </ScrollView>
        <Loading show={loading} />
    </>);
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        padding: 10,
        backgroundColor: '#f5f5f5',
        borderRadius: 5,
        marginTop: 10,
    },
    checkboxControl: {
        marginRight: 5,
    },
    checkboxText: {
        fontSize: 11,
        marginRight: 10,
    },
    input: {
        marginTop: 5,
        marginBottom: 0,
    }
});
