import React, { useState, useEffect } from 'react';
import '../../assets/i18n/i18n'; import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, ScrollView } from 'react-native';
import Alert from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Title, Loading, Copyright, Input, Button } from '../components/Elements';

import { ApiPost } from '../services/ApiServices';

export default function FaleConosco() {

    const [ t, i18n ] = useTranslation();

    const [ cliente, setCliente ] = useState({});

    const [ loading, setLoading ] = useState(true);
    const [ form, setForm ] = useState({
        assunto: '',
        mensagem: '',
    });

    let refs = {};

    useEffect(() => {

        i18n.changeLanguage(localStorage.getItem('idioma'));
        AsyncStorage.getItem('cliente').then((cli) => setCliente(JSON.parse(cli)));

        setLoading(false);
        
    }, []);

    let enviar = () => {
        if (!form.assunto || !form.mensagem) {
            Alert.fire(t('Atencao'), t('MsgPreenchaTodosCampos'));
            return;
        }
        setLoading(true);
        ApiPost('Contato', form).then(res => {
            if (res) {
                Alert.fire(t('Pronto'), t('MsgEnviadoSucessoRetorno'));
                setForm({
                    assunto: '',
                    mensagem: '',
                });
            };
        }).finally(() => {
            setLoading(false);
        });
    }

    let updateForm = (field,value) => {
        setForm({...form, [field]: value});
    };

    return(
        <ScrollView style={{ padding: 15 }}>
            <Title style={{ color: cliente.cor1, fontSize: 30, marginBottom: 10 }}>{t('FaleConosco').toUpperCase()}</Title>

            <Text style={styles.label}>{t('Assunto')}:</Text>
            <Input value={form.assunto} autoCompleteType={'name'} maxLength={500}
                ref={(ref) => refs.assunto = ref}
                returnKeyType={'next'} blurOnSubmit={false}
                onChangeText={(val) => updateForm("assunto",val)}
                onSubmitEditing={() => refs.email.focus()}
            />

            <Text style={styles.label}>{t('Mensagem')}:</Text>
            <Input value={form.mensagem} multiline={true} style={{height: 150, textAlignVertical: 'top' }}
                ref={(ref) => refs.mensagem = ref}
                returnKeyType={'go'} onSubmitEditing={() => Keyboard.dismiss()} blurOnSubmit={false}
                onChangeText={(val) => updateForm("mensagem", val)}
            />

            <Button text={t('Enviar')} style={{marginTop: 30, backgroundColor: cliente.cor1 }}
                onPress={ () => enviar() }
            />
            
            <Copyright />
            <Loading show={loading} />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    label: {
        fontSize: 12,
        marginBottom: 3,
    }
});